<template>
  <DsSidebar v-model="isShowing" width-css-class="mobile-menu-sidebar">
    <template #body>
      <div class="mobile-menu-sidebar-body">
        <div class="mobile-menu-sidebar-header">
          <div class="mobile-menu-sidebar-avatar">
            <DsAvatar :alt="fullName" :src="profileImage" size="md" />
            <div class="mobile-menu-sidebar-header-close">
              <button class="cursor-pointer" @click="isShowing = !isShowing">
                <DsCloseOutlineIcon class="mobile-menu-sidebar-header-icon" />
              </button>
            </div>
          </div>
          <p class="mobile-menu-sidebar-name">
            {{ fullName }}
          </p>
          <div class="mobile-menu-sidebar-email">
            {{ email }}
          </div>
        </div>
        <div class="mobile-menu-sidebar-container">
          <ul>
            <li
              v-for="(item, key) in items"
              :key="key"
              class="mobile-menu-sidebar-options"
            >
              <DsLink
                :data-testid="item.dataTestId"
                :to="item.route"
                :class="[
                  isActive(item) ? 'link-offcanvas-active' : 'link-offcanvas',
                  item.cssClasses,
                ]"
                @click="onClose"
              >
                {{ item.label }}
              </DsLink>
            </li>
          </ul>
          <ul class="mobile-menu-sidebar-divider">
            <li
              v-for="(item, key) in itemsAccount"
              :key="key"
              class="mobile-menu-sidebar-options"
            >
              <DsLink
                v-if="Object.keys(item.route).length > 0"
                :data-testid="item.dataTestId"
                :to="item.route"
                :class="[
                  isActive(item) ? 'link-offcanvas-active' : 'link-offcanvas',
                  item.cssClasses,
                ]"
                @click="onClose"
              >
                {{ item.label }}
              </DsLink>
              <DsButton
                v-else
                theme-display="link"
                :data-testid="item.dataTestId"
                class="link-offcanvas text-left"
                @click.prevent="callFunction(item)"
              >
                {{ item.label }}
              </DsButton>
            </li>
          </ul>
        </div>
        <div class="mobile-menu-sidebar-footer">
          <DsLink :to="{ name: 'Logout' }" class="block link-offcanvas">
            {{ t("common.route.logout.link") }}
          </DsLink>
        </div>
      </div>
    </template>
  </DsSidebar>
</template>

<script setup lang="ts">
import { computed, watch } from "vue";

import {
  DsAvatar,
  DsButton,
  DsCloseOutlineIcon,
  DsLink,
  DsSidebar,
} from "@devsalsa/vue-core";
import { useDsResponsive } from "@devsalsa/vue-core";

import type { NavigationItem } from "@/core/shared/components/Navigation/Navigation.types";
import AccountHandler from "@/core/shared/helpers/Account/AccountHandler";

import { useI18n } from "vue-i18n";
import { type LocationAsRelativeRaw, useRoute } from "vue-router";

defineOptions({
  name: "OffCanvas",
});

type OffCanvasProps = {
  items: NavigationItem[];
  itemsAccount: NavigationItem[];
};

const { items, itemsAccount } = defineProps<OffCanvasProps>();

const isShowing = defineModel<boolean>("isOpen", { default: false });

const { t } = useI18n();
const route = useRoute();
const { grid } = useDsResponsive();

const visibleGrid = computed((): boolean => {
  return grid("xl:hidden");
});
const email = computed((): string => {
  return AccountHandler.accountInfo.value?.email ?? "";
});
const fullName = computed((): string => {
  return `${AccountHandler.accountInfo.value?.first_name ?? ""} ${AccountHandler.accountInfo.value?.last_name ?? ""}`;
});
const profileImage = computed((): string => {
  return `${AccountHandler.accountInfo.value?.profile_image ?? ""}`;
});

const onClose = (): void => {
  isShowing.value = false;
};
const isActive = (item: NavigationItem): boolean => {
  return route.name === (item.route as LocationAsRelativeRaw).name;
};
const callFunction = (item: NavigationItem): void => {
  if (item.action && typeof item.action === "function") {
    onClose();
    item.action();
  }
};

watch(visibleGrid, (newValue) => {
  isShowing.value = isShowing.value && newValue;
});
</script>

<style lang="scss" scoped>
.link-offcanvas {
  @apply text-gray-500 text-sm font-medium p-2 no-underline hover:no-underline
  hover:text-gray-900 hover:bg-gray-100 hover:rounded-lg;
  &-active {
    @apply text-sm font-medium py-2 px-2 text-gray-900 bg-gray-100 rounded-lg no-underline hover:no-underline;
  }
}

.mobile-menu-sidebar {
  &-header {
    @apply px-6 border-b border-gray-200 pb-4 z-10 bg-white sticky top-0 pt-6;

    &-close {
      @apply ml-3 flex h-7 items-center;
    }

    &-icon {
      @apply w-8 h-8 fill-gray-900;
    }
  }

  &-avatar {
    @apply flex items-start justify-between;
  }

  &-name {
    @apply text-lg font-medium text-gray-900 pt-2;
  }

  &-email {
    @apply text-gray-500 text-sm font-normal;
  }

  &-container {
    @apply relative py-6 flex-1 overflow-y-auto;
  }

  &-body {
    @apply flex h-full flex-col relative;
  }

  &-footer {
    @apply border-t border-gray-200 p-4 w-full bg-white sticky bottom-0;
  }

  &-options {
    @apply space-y-2 flex flex-col px-4;
  }

  &-divider {
    @apply border-t border-gray-200 mt-4 pt-4;
  }
}
</style>
